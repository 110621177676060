<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { mdiClipboardTextOutline } from "@mdi/js";
import { getProjectName } from "@/services/api/iam.api";

const props = defineProps({
  projectId: undefined,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const breadCrumbName = ref(undefined);

watch(
  () => props.projectId,
  () => {
    getBreadCrumbName();
  },
  { immediate: true }
);

const breadcrumbs = computed(() => {
  let list = [{ default: "IAM-ProjectsOverview" }];
  if (props.addModus) {
    list.push({
      text: "toevoegen",
      disabled: true,
    });
  } else {
    list.push({
      text: breadCrumbName.value,
      to: { name: "IAM-ProjectDetails", params: { projectId: props.projectId } },
    });
  }
  return list;
});

async function getBreadCrumbName() {
  if (!props.addModus) {
    const response = await getProjectName(props.projectId);
    breadCrumbName.value = response.data;
  }
}

function updateBreadCrumbName() {
  console.log("update breadCrumbName project");
  getBreadCrumbName();
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h2>
          <v-icon>{{ mdiClipboardTextOutline }}</v-icon> Project
        </h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectDetails' }" :disabled="addModus">
                <v-list-item-title>Details</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectLinksOverview' }" :disabled="addModus">
                <v-list-item-title>Verwijzingen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectUsers' }" :disabled="addModus">
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectUserGroups' }" :disabled="addModus">
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectResources' }" :disabled="addModus">
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectRoles' }" :disabled="addModus">
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view @update-project="updateBreadCrumbName"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
